<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="1200px"
    v-if="isDataLoadedFromServer"
    style="background-color: white !important; height: 80vh !important"
  >
    <div class="bg-white poppins modal-root">
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">
          Edit Order ({{ serverData.data[1].data[1].value }})
        </h3>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>

      <div class="d-block pa-4">
        <!--begin::Body-->
        <v-stepper
          v-model="tab"
          non-linear
          v-if="isStepperReady"
          @change="updateValidationStateOfSteps"
        >
          <v-stepper-header class="step-head-main">
            <v-stepper-step
              v-for="(item, i) in categories"
              :key="i"
              class="step-head"
              editable
              complete
              complete-icon="mdi-check-circle"
              edit-icon="mdi-check"
              error-icon="mdi-alert-circle"
              :step="i"
              :rules="[
                () => {
                  return item.valid;
                },
              ]"
            >
              {{ item.name }}
              <small v-if="!item.valid">Invalid</small>
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="0" class="pt-2">
              <v-card color="basil" flat>
                <pop-up-time-line
                  :item="getItemData('timeline')"
                ></pop-up-time-line>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="1" class="pt-2">
              <v-card color="basil" flat class="row">
                <div class="col-12 col-sm-6">
                  <v-text-field
                    class
                    v-model="$v.consignee.consignee_name.$model"
                    label="Consignee name"
                    clearable
                    outlined
                    dense
                    :error-messages="consignee_nameErrors"
                    @input="$v.consignee.consignee_name.$touch()"
                    @blur="$v.consignee.consignee_name.$touch()"
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="$v.consignee.consignee_phone.$model"
                    label="Consignee phone"
                    clearable
                    outlined
                    dense
                    :error-messages="consignee_phoneErrors"
                    @input="$v.consignee.consignee_phone.$touch()"
                    @blur="$v.consignee.consignee_phone.$touch()"
                  ></v-text-field>
                </div>
                <!-- <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="$v.consignee.consignee_mobile.$model"
                    label="Consignee mobile"
                    clearable
                    outlined
                    dense
                    :error-messages="consignee_mobileErrors"
                    @input="$v.consignee.consignee_mobile.$touch()"
                    @blur="$v.consignee.consignee_mobile.$touch()"
                  ></v-text-field>
                </div> -->
                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="consignee.consignee_email"
                    label="Consignee email"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="2" class="pt-2">
              <v-card color="basil" flat class="row">
                <div class="col-12 col-sm-6 col-md-4">
                  <v-select
                    v-model="order_details.warehouse_id"
                    label="Warehouse"
                    item-text="text"
                    item-value="index"
                    :items="serverData.warehouses"
                    clearable
                    outlined
                    dense
                    disabled
                  ></v-select>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <v-select
                    v-model="order_details.carrier_id"
                    label="Carrier"
                    item-text="text"
                    item-value="index"
                    :items="serverData.carriers"
                    clearable
                    outlined
                    dense
                  ></v-select>
                </div>
                <!-- <div class="col-12 col-sm-6 col-md-4">
                  <v-select
                    v-model="order_details.order_type_id"
                    label="Order type"
                    item-text="text"
                    item-value="index"
                    :items="serverData.order_types"
                    clearable
                    outlined
                    dense
                    disabled
                  ></v-select>
                </div> -->

                <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    v-model="$v.order_details.total_price.$model"
                    label="Price"
                    type="Number"
                    clearable
                    dense
                    outlined
                    @change="
                      () => validateMinValue('total_price', 0, 'order_details')
                    "
                    :error-messages="total_priceErrors"
                    @input="$v.order_details.total_price.$touch()"
                    @blur="$v.order_details.total_price.$touch()"
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <v-select
                    v-model="$v.order_details.currency_id.$model"
                    label="Currency"
                    item-text="text"
                    item-value="index"
                    :items="serverData.currencies"
                    clearable
                    outlined
                    dense
                    :error-messages="currency_idErrors"
                    @input="$v.order_details.currency_id.$touch()"
                    @blur="$v.order_details.currency_id.$touch()"
                  ></v-select>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <v-select
                    v-model="$v.order_details.payment_type_id.$model"
                    label="Payment type"
                    item-text="text"
                    item-value="index"
                    :items="serverData.payment_types"
                    clearable
                    outlined
                    dense
                    :error-messages="payment_type_idErrors"
                    @input="$v.order_details.payment_type_id.$touch()"
                    @blur="$v.order_details.payment_type_id.$touch()"
                  ></v-select>
                </div>

                <!-- <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    v-model="$v.order_details.parcel_weight.$model"
                    label="Parcel weight"
                    clearable
                    outlined
                    dense
                    :error-messages="parcel_weightErrors"
                    @input="$v.order_details.parcel_weight.$touch()"
                    @blur="$v.order_details.parcel_weight.$touch()"
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    v-model="$v.order_details.parcel_volume.$model"
                    label="Parcel volume"
                    clearable
                    outlined
                    dense
                    :error-messages="parcel_volumeErrors"
                    @input="$v.order_details.parcel_volume.$touch()"
                    @blur="$v.order_details.parcel_volume.$touch()"
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    v-model="$v.order_details.number_of_boxes.$model"
                    label="Number of boxes"
                    clearable
                    outlined
                    dense
                    :error-messages="number_of_boxesErrors"
                    @input="$v.order_details.number_of_boxes.$touch()"
                    @blur="$v.order_details.number_of_boxes.$touch()"
                  ></v-text-field>
                </div> -->
                <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    v-model="order_details.shipping_price"
                    label="Shipping price"
                    clearable
                    outlined
                    dense
                    type="Number"
                    @change="
                      () =>
                        validateMinValue('shipping_price', 0, 'order_details')
                    "
                  ></v-text-field>
                </div>
                <!-- <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    v-model="order_details.tax_amount"
                    label="Tax amount"
                    clearable
                    outlined
                    dense
                    type="Number"
                    @change="
                      () => validateMinValue('tax_amount', 0, 'order_details')
                    "
                  ></v-text-field>
                </div> -->
                <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    v-model="order_details.cod_amount"
                    label="Cod amount"
                    clearable
                    outlined
                    dense
                    type="Number"
                    @change="
                      () => validateMinValue('cod_amount', 0, 'order_details')
                    "
                  ></v-text-field>
                </div>
                <!-- <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    v-model="order_details.customs_amount"
                    label="Customs amount"
                    clearable
                    outlined
                    dense
                    type="Number"
                    @change="
                      () =>
                        validateMinValue('customs_amount', 0, 'order_details')
                    "
                  ></v-text-field>
                </div> -->
                <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    v-model="order_details.total_discount"
                    label="Discount amount"
                    clearable
                    outlined
                    dense
                    type="Number"
                    @change="
                      () =>
                        validateMinValue('total_discount', 0, 'order_details')
                    "
                  ></v-text-field>
                </div>
                <!-- <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    v-model="order_details.subtotal"
                    label="Subtotal amount"
                    clearable
                    outlined
                    dense
                    type="Number"
                    @change="
                      () => validateMinValue('subtotal', 0, 'order_details')
                    "
                  ></v-text-field>
                </div> -->
                <!-- <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    v-model="order_details.total_declared_amount"
                    label="Total declared amount"
                    clearable
                    outlined
                    dense
                    type="Number"
                    @change="
                      () =>
                        validateMinValue(
                          'total_declared_amount',
                          0,
                          'order_details'
                        )
                    "
                  ></v-text-field>
                </div> -->
                <!-- <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    v-model="order_details.tax_percentage"
                    label="Tax percentage"
                    clearable
                    outlined
                    dense
                    type="Number"
                    @change="
                      () =>
                        validateMinValue('tax_percentage', 0, 'order_details')
                    "
                  ></v-text-field>
                </div> -->
                <!-- total_tax: null, //need to add -->
                <!-- tax_percentage: null, //need to add -->
                <!-- total_discount: null, //need to add -->
                <!-- subtotal: null, //need to add -->

                <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    v-model="order_details.total_tax"
                    label="Total tax"
                    clearable
                    outlined
                    dense
                    type="Number"
                    @change="
                      () => validateMinValue('total_tax', 0, 'order_details')
                    "
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    v-model="order_details.tax_percentage"
                    label="Tax percentage"
                    clearable
                    outlined
                    dense
                    type="Number"
                    @change="
                      () =>
                        validateMinValue('tax_percentage', 0, 'order_details')
                    "
                  ></v-text-field>
                </div>
                <!-- <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    v-model="order_details.total_discount"
                    label="Total discount"
                    clearable
                    outlined
                    dense
                    type="Number"
                    @change="
                      () =>
                        validateMinValue('total_discount', 0, 'order_details')
                    "
                  ></v-text-field>
                </div> -->
                <div class="col-12 col-sm-6 col-md-4">
                  <v-text-field
                    v-model="order_details.subtotal"
                    label="Subtotal"
                    clearable
                    outlined
                    dense
                    type="Number"
                    @change="
                      () => validateMinValue('subtotal', 0, 'order_details')
                    "
                  ></v-text-field>
                </div>
                <div class="col-12">
                  <v-text-field
                    v-model="order_details.comment"
                    label="Comment"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>

                <div class="col-12 col-sm-6 col-md-4">
                  <v-select
                    v-model="order_details.allocation_priority"
                    label="Allocation Priority"
                    item-text="text"
                    item-value="index"
                    :items="serverData.allocation_priorities"
                    clearable
                    outlined
                    dense
                  ></v-select>
                </div>

                <div class="col-12">
                  <v-text-field
                    v-model="order_details.comment"
                    label="Comment"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="3" class="pt-2">
              <v-card color="basil" flat class="row">
                <div class="col-12 col-sm-6 col-md-4">
                  <v-select
                    v-model="allocation_parameters.inventory_condition"
                    label="Inventory condition"
                    item-text="text"
                    item-value="index"
                    :items="serverData.inventory_conditions"
                    clearable
                    outlined
                    dense
                  ></v-select>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <v-select
                    v-model="allocation_parameters.allocation_type"
                    label="Allocation type"
                    item-text="text"
                    item-value="index"
                    :items="serverData.allocation_types"
                    clearable
                    outlined
                    dense
                  ></v-select>
                </div>
                <div class="col-12 col-sm-6 col-md-4">
                  <v-select
                    v-model="allocation_parameters.location_priority"
                    label="Location priority"
                    item-text="text"
                    item-value="index"
                    :items="serverData.allocation_priorities"
                    clearable
                    outlined
                    dense
                  ></v-select>
                </div>
              </v-card>
            </v-stepper-content>

            <v-stepper-content step="4" class="pt-2">
              <v-card color="basil" flat>
                <pop-up-table :item="getItemData('table')"></pop-up-table>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="5" class="pt-2">
              <div class="row">
                <div class="col-12 col-sm-6">
                  <v-autocomplete
                    @change="onCountryChange"
                    v-model="$v.address.country_id.$model"
                    label="Country"
                    item-text="text"
                    item-value="index"
                    :items="serverData.countries"
                    clearable
                    outlined
                    dense
                    :error-messages="country_idErrors"
                    @input="$v.address.country_id.$touch()"
                    @blur="$v.address.country_id.$touch()"
                  ></v-autocomplete>
                </div>
                <div class="col-12 col-sm-6">
                  <v-combobox
                    ref="stateCombo"
                    v-model="$v.address.state.$model"
                    :search-input.sync="state_text"
                    :items="serverData.states"
                    :disabled="isStatesLoaded"
                    :messages="oldValueText('state')"
                    item-text="title"
                    item-value="title"
                    label="State"
                    outlined
                    required
                    dense
                    :error-messages="stateErrors"
                    @input="$v.address.state.$touch()"
                    @blur="$v.address.state.$touch()"
                  >
                    <template v-slot:append-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <button
                              class="btn btn-info pl-2"
                              @click="
                                () =>
                                  createNewItem(
                                    'state',
                                    address.country_id,
                                    onCountryChange,
                                    state_text
                                  )
                              "
                            >
                              Create new
                            </button>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </div>
                <div class="col-12 col-sm-6">
                  <v-combobox
                    ref="cityCombo"
                    v-model="$v.address.city.$model"
                    :search-input.sync="city_text"
                    :items="serverData.cities"
                    :disabled="isCitiesLoaded"
                    :messages="oldValueText('city')"
                    item-text="title"
                    item-value="title"
                    label="City"
                    persistent-hint
                    outlined
                    dense
                    :error-messages="cityErrors"
                    @input="$v.address.city.$touch()"
                    @blur="$v.address.city.$touch()"
                  >
                    <template v-slot:append-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <button
                              class="btn btn-info pl-2"
                              @click="
                                () =>
                                  createNewItem(
                                    'city',
                                    address.state.id,
                                    onStateChange,
                                    city_text
                                  )
                              "
                            >
                              Create new
                            </button>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </div>
                <div class="col-12 col-sm-6">
                  <v-combobox
                    ref="areaCombo"
                    :disabled="isAreasLoaded"
                    v-model="address.area"
                    :search-input.sync="area_text"
                    :items="serverData.areas"
                    :messages="oldValueText('area')"
                    item-text="title"
                    item-value="title"
                    label="Area"
                    persistent-hint
                    outlined
                    dense
                    :error-messages="areaMessage"
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            <button
                              class="btn btn-info pl-2"
                              @click="
                                () =>
                                  createNewItem(
                                    'area',
                                    address.city.id,
                                    onCityChange,
                                    area_text
                                  )
                              "
                            >
                              Create new
                            </button>
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </div>

                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="address.province"
                    label="Province"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="address.town"
                    label="Town"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>
                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="$v.address.address.$model"
                    label="Address"
                    clearable
                    outlined
                    dense
                    :error-messages="addressErrors"
                    @input="$v.formData.address.$touch()"
                    @blur="$v.formData.address.$touch()"
                  ></v-text-field>
                </div>

                <div class="col-12 col-sm-6">
                  <v-text-field
                    v-model="address.zipcode"
                    label="Zipcode"
                    clearable
                    outlined
                    dense
                  ></v-text-field>
                </div>
              </div>
            </v-stepper-content>
            <v-stepper-content step="6" class="pt-2">
              <v-card color="basil" flat>
                <pop-up-text-group
                  :item="getItemData('text')"
                ></pop-up-text-group>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="7" class="pt-2">
              <v-card color="basil" flat>
                <v-col cols="12">
                  <sku-datatable
                    :setSku="setSkus"
                    :skus="formData.skus"
                    :skuItem="serverData.skus"
                    :clientId="serverData.order.client_id"
                    :enableAddItem="serverData.order.enable_add_new_items"
                  ></sku-datatable>
                </v-col>
              </v-card>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <!--end::Body-->
        <CreateLocation
          :location-id="getLocationId"
          :location-type="getLocationType"
          :location-function="getLocationFunc"
          :locationSetValue="setTextValue"
          :initValue="getLocationInitValue"
          ref="location"
        ></CreateLocation>

        <div
          class="d-flex align-center justify-center justify-sm-space-between modal-action flex-wrap pt-4"
        >
          <v-btn
            width="120"
            @click="showPrevOrder(serverData.order.id)"
            class="order-4 order-sm-1"
            >previous</v-btn
          >

          <button
            type="reset"
            class="btn btn-light-danger px-5 py-3 order-3 order-sm-2"
            @click="showCancelModal"
          >
            Cancel Order
          </button>

          <button
            type="submit"
            class="btn btn-warning px-5 py-3 order-2 order-sm-3"
            @click="showHoldOnModal"
          >
            On Hold
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 order-1 order-sm-4"
            @click="submitEditForm"
          >
            Update information and Audit
          </button>
          <v-btn
            width="120"
            @click="() => showNextOrder(serverData.order.id)"
            class="order-5 order-sm-5"
            >next</v-btn
          >
        </div>
      </div>
    </div>
    <v-dialog v-model="dialog2" scrollable max-width="300px">
      <v-card>
        <v-card-title>Select Hold On Reason</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="holdOn_reason" column>
            <v-radio
              v-for="item in extraData.hold_reasons"
              :label="item.text"
              :value="item.index"
              :key="item.value"
            ></v-radio>
          </v-radio-group>

          <v-menu
            v-if="holdOn_reason === 1030"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="holdOn_date"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="holdOn_date"
              @input="menu2 = false"
            ></v-date-picker>
          </v-menu>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="dialog2 = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="actionOnHold">
            On Hold
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog3" scrollable max-width="300px">
      <v-card>
        <v-card-title>Cancel Reason</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 300px">
          <v-radio-group v-model="cancel_reason" column>
            <v-radio
              v-for="item in extraData.cancel_reasons"
              :label="item.text"
              :value="item.index"
              :key="item.value"
            ></v-radio>
          </v-radio-group>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="dialog3 = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="actionCancel">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import SkuDatatable from "@/own/components/orderManagement/pendingAuditOrders/skuContainer/SkuDatatable";

import { validationMixin } from "vuelidate";
import { required, requiredIf } from "vuelidate/lib/validators";
import PopUpTimeLine from "@/own/components/fulfillment/orders/popup/PopUpTimeLine";
import PopUpTable from "@/own/components/fulfillment/orders/popup/PopUpTable";
import PopUpTextGroup from "@/own/components/fulfillment/orders/popup/PopUpTextGroup";

import CreateLocation from "./CreateLocation.vue";
import fieldValueValidation from "@/own/mixins/fieldValueValidation.js";

import {
  handleCityValueChange,
  handleCountryValueChange,
  handleStateValueChange,
} from "@/own/libs/handle-locations";

export default {
  name: "EditItem",
  mixins: [validationMixin, fieldValueValidation],
  components: {
    PopUpTextGroup,
    PopUpTimeLine,
    SkuDatatable,
    PopUpTable,
    CreateLocation,
  },
  props: {
    itemForAction: {
      required: false,
      type: Number,
    },
    refresher: {
      required: true,
      type: Function,
    },
    pageLoader: {
      required: true,
      type: Function,
    },
    extraData: {
      required: true,
      type: Object,
    },
    showNextOrder: {
      required: true,
      type: Function,
    },
    showPrevOrder: {
      required: true,
      type: Function,
    },
  },
  // ["XDOCK" => 9, "XSDOCK" => 17, "BOX" => 1, "RT" => 7, "RVP" => 4]
  // $UAECountryId = 229

  validations() {
    return {
      address: {
        address: { required },
        city: { required },
        state: { required },
        country_id: { required },
      },
      consignee: {
        consignee_name: { required },
        consignee_phone: { required },
        // consignee_mobile: { required },
      },
      order_details: {
        // warehouse_id: { required },

        // comment: {
        //   required: requiredIf(
        //     () =>
        //       this.order_details.order_type_id === 9 ||
        //       this.order_details.order_type_id === 1 ||
        //       this.order_details.order_type_id === 7
        //   ),
        // },
        total_price: {
          required: requiredIf(function () {
            return (
              this.order_details.payment_type_id === 1 ||
              this.order_details.country_id !== 229
            );
          }),
        },
        currency_id: { required },
        payment_type_id: { required },
        // order_type_id: { required },
        // parcel_weight: {
        //   required: requiredIf(function () {
        //     return (
        //       this.order_details.order_type_id === 9 ||
        //       this.order_details.order_type_id === 1
        //     );
        //   }),
        // },
        // parcel_volume: {
        //   required: requiredIf(function () {
        //     return (
        //       this.order_details.order_type_id === 9 ||
        //       this.order_details.order_type_id === 1
        //     );
        //   }),
        // },
        // number_of_boxes: {
        //   required: requiredIf(function () {
        //     return (
        //       this.order_details.order_type_id === 9 ||
        //       this.order_details.order_type_id === 17
        //     );
        //   }),
        // },
      },
    };
  },
  data: () => ({
    areaMessage: "",

    state_text: null,
    city_text: null,
    area_text: null,

    location_type: null,
    location_id: null,
    location_function: null,
    location_initValue: null,

    old_address: {
      state: null,
      city: null,
      area: null,
    },

    formData: {
      id: null, //++

      skus: [], //++
    },
    address: {
      address: null,
      area: null,
      province: null,
      town: null,
      city: null,
      state: null,
      country_id: null,
      zipcode: null,
    },
    consignee: {
      consignee_name: null,
      consignee_phone: null,
      // consignee_mobile: null,//--
      consignee_email: null,
    },
    serverData: null,
    order_details: {
      warehouse_id: null, //++
      carrier_id: null, //++
      comment: null, //++
      total_price: null,
      currency_id: null, //++
      payment_type_id: null, //++
      cod_amount: null, //++
      // order_type_id: null,
      // parcel_weight: null,
      // parcel_volume: null,
      // number_of_boxes: null,
      shipping_price: null, //need to add
      total_tax: null, //need to add
      tax_percentage: null, //need to add
      total_discount: null, //need to add
      subtotal: null, //need to add
      allocation_priority: null,
    },
    allocation_parameters: {
      inventory_condition: 1,
      allocation_type: null,
      location_priority: null,
    },

    dialog2: false,
    dialog3: false,
    dialog: false,
    menu2: false,
    cancel_reason: null,
    holdOn_reason: null,
    holdOn_date: null,
    tab: 0,

    // ckeditorType: ClassicEditor,
    updateValueTimer: setTimeout(() => {}, 0),
    locationFetchTriggerTime: 600,
  }),
  methods: {
    /**
     * Schedules an update for the specified location type after a delay.
     *
     * @param {string} type - The type of location to update. Must be one of:
     *   - 'state'
     *   - 'city'
     *   - 'area'
     * @emits {string} update-location - Triggers an event when the location update is complete (optional).
     * @example updateValue('city')
     */
    updateValue(type) {
      clearTimeout(this.updateValueTimer);
      this.updateValueTimer = setTimeout(() => {
        this.onBlurValueCheck(type);
      }, this.locationFetchTriggerTime);
    },
    oldValueText(type) {
      let message = "";
      switch (type) {
        case "state":
          if (this.old_address.state) {
            message = `Received: ${this.old_address.state}`;
          }

          break;
        case "city":
          if (this.old_address.city) {
            message = `Received: ${this.old_address.city}`;
          }
          break;
        case "area":
          if (this.old_address.area) {
            message = `Received: ${this.old_address.area}`;
          }
          break;
      }
      return message;
    },
    async onBlurValueCheck(type) {
      switch (type) {
        case "state": {
          if (this.state_text == "") {
            return;
          }

          const item = this.serverData?.states?.find(
            (state) =>
              state.title.toLowerCase().replace(/[^a-zA-Z0-9]/g, "") ===
              this.state_text?.toLowerCase().replace(/[^a-zA-Z0-9]/g, "")
          );

          if (!item) {
            this.serverData.cities = null;
            this.serverData.areas = null;
          } else {
            if (item.title != this.state_text) {
              this.state_text = item.title;
            } else {
              await this.onStateChange(item.title);
              this.$refs.stateCombo.blur();
            }
          }

          break;
        }
        case "city": {
          if (this.city_text == "") {
            return;
          }

          const item = this.serverData?.cities?.find(
            (city) =>
              city.title.toLowerCase().replace(/[^a-zA-Z0-9]/g, "") ===
              this.city_text?.toLowerCase().replace(/[^a-zA-Z0-9]/g, "")
          );
          if (!item) {
            this.serverData.areas = null;
          } else {
            if (item.title != this.city_text) {
              this.city_text = item.title;
            } else {
              await this.onCityChange(item.title);
              this.$refs.cityCombo.blur();
            }
          }

          break;
        }
      }
    },
    validationValueCheck(type) {
      let validation = null;
      switch (type) {
        case "state":
          try {
            if (
              this.serverData.states.filter(
                (state) => state.title === this.state_text
              ).length === 0
            ) {
              validation = "Please, select valid state!";
              this.serverData.cities = null;
              this.serverData.areas = null;
            }
          } catch {
            break;
          }

          break;
        case "city":
          try {
            if (
              this.serverData.cities.filter(
                (city) => city.title === this.city_text
              ).length === 0
            ) {
              validation = "Please, select valid city!";
              this.serverData.areas = null;
            }
          } catch {
            break;
          }

          break;
        case "area":
          try {
            if (
              this.serverData.areas.filter(
                (area) => area.title === this.area_text
              ).length === 0
            ) {
              validation = "Please, select valid area!";
            }
          } catch {
            validation = "Please, select valid city!";
            break;
          }

          break;
      }
      return validation;
    },
    setTextValue(type, value = "") {
      switch (type) {
        case "state":
          {
            this.state_text = value;
            if (value == "") {
              return;
            }
            const state = this.serverData?.states?.find(
              (state) =>
                state.title.toLowerCase().replace(/[^a-zA-Z0-9]/g, "") ===
                this.state_text?.toLowerCase().replace(/[^a-zA-Z0-9]/g, "")
            );
            this.address.state = state;
            // this.onStateChange(this.address.state.title);
          }
          break;
        case "city":
          {
            this.city_text = value;
            if (value == "") {
              return;
            }
            const city = this.serverData?.cities?.find(
              (city) =>
                city.title.toLowerCase().replace(/[^a-zA-Z0-9]/g, "") ===
                this.city_text?.toLowerCase().replace(/[^a-zA-Z0-9]/g, "")
            );
            if (city) {
              this.address.city = city;
            }

            // this.onCityChange(this.address.city.title);
          }
          break;
        case "area": {
          this.area_text = value;

          if (value == "") {
            return;
          }
          const area = (this.address.area = this.serverData?.areas?.find(
            (area) =>
              area.title.toLowerCase().replace(/[^a-zA-Z0-9]/g, "") ===
              this.area_text.toLowerCase().replace(/[^a-zA-Z0-9]/g, "")
          ));
          if (area) {
            this.address.area = area;
          }
        }
      }
    },
    createNewItem(type, id, func, value) {
      this.location_type = type;
      this.location_id = id;
      this.location_function = func;
      this.location_initValue = value;
      this.$nextTick(() => {
        this.$refs.location.toggleModal();
      });
    },
    updateValidationStateOfSteps() {
      this.$v.$touch();
      this.categories.forEach((cat) => {
        cat.valid = !cat.rules(this);
      });
    },
    resetValidationStateOfSteps() {
      this.categories.forEach((cat) => {
        cat.valid = true;
      });
    },
    getItemData(type) {
      return this.infoData.filter((data) => data.type === type)[0];
    },
    actionUpdateAndAudit() {},
    showCancelModal() {
      this.dialog3 = !this.dialog3;
    },
    actionCancel() {
      if (this.cancel_reason) {
        this.pageLoader(true);
        this.dialog3 = false;
        const data = {
          order_ids: [this.itemForAction],
          reason: this.cancel_reason,
        };
        ApiService.post(`/order_management/pending_audit_orders/cancel`, data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Cancel reason registered!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.pageLoader(false);
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    showHoldOnModal() {
      this.dialog2 = !this.dialog2;
    },
    actionOnHold() {
      if (this.holdOn_reason) {
        this.pageLoader(true);
        this.dialog2 = false;
        const data = {
          order_ids: [this.itemForAction],
          reason: this.holdOn_reason,
          scheduled_date: this.holdOn_date,
        };
        ApiService.post(`/order_management/pending_audit_orders/hold`, data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Hold On reason registered!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.pageLoader(false);
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    setSkus(item) {
      this.formData.skus = [...item];
    },
    handleFormValidation(fieldName, dataName = "formData") {
      const errors = [];
      if (!this.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in this.$v[`${dataName}`][fieldName]) {
        !this.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in this.$v[`${dataName}`][fieldName]) {
        !this.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in this.$v[`${dataName}`][fieldName]) {
        !this.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in this.$v[`${dataName}`][fieldName]) {
        !this.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in this.$v[`${dataName}`][fieldName]) {
        !this.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    async loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.itemForAction };
      await ApiService.post(`/order_management/pending_audit_orders/edit`, data)
        .then((response) => {
          this.serverData = response.data;
          // this.order_details.location_priority =
          //   response.data.allocation_priorities[0].index;
          this.allocation_parameters.location_priority =
            response.data.allocation_priorities[0].index;
          this.allocation_parameters.inventory_condition =
            response.data.inventory_conditions[0].index;
          this.resetEditForm().finally(() => {
            this.pageLoader(false);
            this.updateValidationStateOfSteps();
          });
        })
        .catch(() => {
          this.dialog = false;
          this.pageLoader(false);
        });
    },

    convertToFormData() {
      return this.formData;
    },

    submitEditForm() {
      this.updateValidationStateOfSteps();
      if (this.$v.$invalid) {
        this.$v.$touch();

        return;
      }
      this.$v.$touch();
      if (this.formData.skus.length === 0) {
        Swal.fire({
          title: "Warning",
          html: `<div>At least one item should be added</div>`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      if (
        this.validationValueCheck("state") ||
        this.validationValueCheck("city")
      ) {
        Swal.fire({
          title: "Warning",
          html: `<div>Invalid address!</div>`,
          icon: "warning",
          showConfirmButton: false,
          timer: 2500,
        });
        return;
      }
      this.pageLoader(true);
      let data = {
        ...this.formData,
        ...this.address,
        ...this.consignee,
        ...this.order_details,
        ...this.allocation_parameters,
      };
      data.state = this.address.state.id;
      data.city = this.address.city.id;
      if (this.address.area) {
        data.area = this.address.area.id;
      }
      ApiService.post(`/order_management/pending_audit_orders/update`, data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: `Order has been updated`,
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          let id = this.serverData.order.id;
          this.showNextOrder(id);
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    async resetEditForm() {
      this.$v.$reset();
      const dataNames = ["formData", "address", "consignee", "order_details"];
      if (this.serverData) {
        dataNames.forEach((dataName) => {
          let copy = { ...this.serverData.order };
          Object.entries(this[`${dataName}`]).forEach((localD) => {
            Object.entries(this.serverData.order).forEach((serverD) => {
              if (localD[0] === serverD[0] && localD[0] !== "image") {
                this[`${dataName}`][localD[0]] = copy[serverD[0]];
              }
            });
          });
        });
        this.old_address = {
          state: this.serverData?.order?.state,
          city: this.serverData?.order?.city,
          area: this.serverData?.order?.area,
        };

        // this.order_details.shipping_type_id = !this.serverData.order
        //   .shipping_type_id
        //   ? 2
        //   : this.serverData.order.shipping_type_id;

        if (this.serverData.order.country_id) {
          await this.onCountryChange(
            this.serverData.order.country_id,
            true,
            true
          );
        }
      } else {
        this.formData = {
          id: null,
          skus: [],
        };
        this.address = {
          address: null,
          area: null,
          province: null,
          town: null,
          city: null,
          state: null,
          country_id: null,
          zipcode: null,
        };
        this.consignee = {
          consignee_name: null,
          consignee_phone: null,
          // consignee_mobile: null,
          consignee_email: null,
        };
        this.order_details = {
          warehouse_id: null,
          carrier_id: null,
          comment: null,
          total_price: null,
          currency_id: null,
          payment_type_id: null,
          cod_amount: null,

          // order_type_id: null,
          // parcel_weight: null,
          // parcel_volume: null,
          // number_of_boxes: null,

          shipping_price: null, //need to add
          total_tax: null, //need to add
          tax_percentage: null, //need to add
          total_discount: null, //need to add
          subtotal: null, //need to add
          allocation_priority: null,
        };

        this.allocation_parameters = {
          inventory_condition: 1,
          allocation_type: null,
          location_priority: null,
        };
      }

      this.tab = 0;
      this.resetValidationStateOfSteps();
    },
    toggleModal() {
      if (this.dialog) {
        this.serverData = null;
        this.resetEditForm();
      } else {
        this.loadDataFromServer();
      }
      this.dialog = !this.dialog;
    },
    async onCountryChange(val, safe = true, ) {
      this.pageLoader(true);
      try {
        await handleCountryValueChange.call(this, val, safe, undefined, undefined,this.serverData?.order?.organization_id );
        await this.updateValue("state");
      } catch  {
        this.pageLoader(false);
      } finally {
        this.pageLoader(false);
      }
    },
    async onStateChange(value, safe = true, is_id = false) {
      // clearTimeout(this.cityFetchTimer);
      // this.cityFetchTimer = setTimeout(async () => {
      this.pageLoader(true);
      try {
        await handleStateValueChange.call(this, value, safe, is_id,undefined, undefined, this.serverData?.order?.organization_id);
        await this.updateValue("city");
      } catch {
        this.pageLoader(false);
      } finally {
        this.pageLoader(false);
      }
      // }, this.locationFetchTriggerTime);
    },
    async onCityChange(value, safe = true, is_id = false) {
      // clearTimeout(this.areaFetchTimer);
      // this.areaFetchTimer = setTimeout(async () => {
      this.pageLoader(true);
      try {
        await handleCityValueChange.call(this, value, safe, is_id,undefined, undefined,  this.serverData?.order?.organization_id);
        this.areaMessageGenerator();
      } catch {
        this.pageLoader(false);
      } finally {
        this.pageLoader(false);
      }
      // }, this.locationFetchTriggerTime);
    },
    areaMessageGenerator() {
      this.areaMessage = this.validationValueCheck("area")
        ? this.validationValueCheck("area")
        : "";
    },
  },
  computed: {
    categories() {
      return [
        {
          name: "Status History",
          valid: true,
          rules: () => {
            return false;
          },
        },
        {
          name: "Consignee Details",
          valid: true,
          rules: (el) => {
            try {
              return el[`$v`][`consignee`].$invalid;
            } catch {
              return true;
            }
          },
        },
        {
          name: "Order Details",
          valid: true,
          rules: (el) => {
            try {
              return el[`$v`][`order_details`].$invalid;
            } catch {
              return true;
            }
          },
        },
        {
          name: "Allocation Parameters",
          valid: true,
          rules: () => {
            return false;
          },
        },
        {
          name: "Call History",
          valid: true,
          rules: () => {
            return false;
          },
        },
        {
          name: "Address",
          valid: true,
          rules: (el) => {
            try {
              return (
                el[`$v`][`address`].$invalid ||
                this.validationValueCheck("state") ||
                this.validationValueCheck("city") ||
                this.validationValueCheck("area")
              );
            } catch {
              return true;
            }
          },
        },
        {
          name: "Current Status",
          valid: true,
          rules: () => {
            return false;
          },
        },
        {
          name: "Items",
          valid: true,
          rules: (el) => {
            try {
              return !el.formData.skus.length > 0;
            } catch {
              return true;
            }
          },
        },
      ];
    },
    getLocationType() {
      return this.location_type;
    },
    getLocationId() {
      return this.location_id;
    },
    getLocationFunc() {
      return this.location_function;
    },
    getLocationInitValue() {
      return this.location_initValue;
    },
    isStepperReady() {
      try {
        return !!this.$v;
      } catch (error) {
        return false;
      }
    },
    states: function () {
      return this.serverData.states;
    },
    cities: function () {
      return this.serverData.cities;
    },
    areas: function () {
      return this.serverData.areas;
    },
    isStatesLoaded: function () {
      return !this.serverData.states;
    },
    isCitiesLoaded: function () {
      return !this.serverData.cities;
    },
    isAreasLoaded: function () {
      return !this.serverData.areas;
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    infoData() {
      return this.serverData.data;
    },
    currency_idErrors: function () {
      return this.handleFormValidation("currency_id", "order_details");
    },
    payment_type_idErrors: function () {
      return this.handleFormValidation("payment_type_id", "order_details");
    },
    total_priceErrors: function () {
      return this.handleFormValidation("total_price", "order_details");
    },
    consignee_nameErrors: function () {
      return this.handleFormValidation("consignee_name", "consignee");
    },
    consignee_phoneErrors: function () {
      return this.handleFormValidation("consignee_phone", "consignee");
    },
    // consignee_mobileErrors: function () {
    //   return this.handleFormValidation("consignee_mobile", "consignee");
    // },
    addressErrors: function () {
      return this.handleFormValidation("address", "address");
    },
    cityErrors: function () {
      let error = this.handleFormValidation("city", "address");
      let ms = this.validationValueCheck("city");
      let old = this.oldValueText("city");
      if (ms && error[0]) {
        // error.push(ms);
        if (old) {
          error[0] = ms + " " + error[0] + ". " + old;
        } else {
          error[0] = ms + " " + error[0];
        }
      } else if (ms) {
        if (old) {
          error[0] = ms + " " + old;
        } else {
          error[0] = ms + " ";
        }
      }
      return error;
    },
    country_idErrors: function () {
      return this.handleFormValidation("country_id", "address");
    },
    stateErrors: function () {
      let error = this.handleFormValidation("state", "address");
      let ms = this.validationValueCheck("state");
      let old = this.oldValueText("state");
      if (ms && error[0]) {
        // error.push(ms);
        if (old) {
          error[0] = ms + " " + error[0] + ". " + old;
        } else {
          error[0] = ms + " " + error[0];
        }
      } else if (ms) {
        if (old) {
          error[0] = ms + " " + old;
        } else {
          error[0] = ms + " ";
        }
      }
      return error;
    },
    warehouse_idErrors: function () {
      return this.handleFormValidation("warehouse_id", "order_details");
    },
    parcel_weightErrors: function () {
      return this.handleFormValidation("parcel_weight", "order_details");
    },
    parcel_volumeErrors: function () {
      return this.handleFormValidation("parcel_volume", "order_details");
    },
    number_of_boxesErrors: function () {
      return this.handleFormValidation("number_of_boxes", "order_details");
    },
  },
  watch: {
    state_text: {
      handler(value, oldValue) {
        if (!this.serverData.countries) {
          this.serverData.states = null;
          this.serverData.cities = null;
          this.serverData.areas = null;
          return;
        }
        if (value == "" || value == null || value == undefined) {
          return;
        }
        if (value == oldValue) {
          return;
        }
        this.updateValue("state");
      },
      deep: true,
    },
    city_text: {
      handler(value, oldValue) {
        if (!this.serverData.states) {
          this.serverData.cities = null;
          this.serverData.areas = null;
          return;
        }
        if (value == "" || value == null || value == undefined) {
          return;
        }
        if (value == oldValue) {
          return;
        }
        this.updateValue("city");
      },
      deep: true,
    },
    area_text: {
      handler(value, oldValue) {
        if (!this.serverData.cities) {
          this.serverData.areas = null;
          return;
        }
        if (value == "" || value == null || value == undefined) {
          return;
        }
        if (value == oldValue) {
          return;
        }
        this.areaMessageGenerator();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.modal-body {
  height: 100%;
  align-content: flex-end;
}
.modal-root {
  background-color: white !important;
  height: 100%;
}
.modal-action {
  justify-self: flex-end;
  height: 100%;
}
.form-body {
  max-height: 80vh !important;
  height: 80vh !important;
  overflow-y: scroll;
}
.step-head {
}
.step-head-main {
  display: flex;
  flex-flow: row;
  overflow-x: scroll;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
}
</style>
